.award-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.award-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.award-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.award-name {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #000;
  background-color: #f1eee8e3;
}

.award-description {
    text-align: center;
    margin: 20px 0;
    color: #000;
  background-color: #f1eee8e3;
}

.award-level{
   /* position:absolute; */
    height:200px;
    width:200px;
    top: 40px;
    left : 40px;
    z-index: 1;
  }
  
  .award-difficulty
  {
    position:absolute;
    height:300px;
    width:300px;
    top: 40px;
    z-index: -1;
  }

  .award-image{
    position: relative; 
    left: 0; 
    top: 0;
  }