.user-databar-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 0px;
}

.user-databar-content img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}
