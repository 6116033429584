.binary-question-container {
    background: #f0f0f0;
    padding: 1rem;
    border-radius: 5px;
    width: 100%;
    height: auto;
}
  
.binary-question-item {
    --background: #07814c;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
    width: 100%;
}
  
.binary-question-text {
    padding: 0.5rem;
    color: white;
    width: 100%;
}
  
.binary-buttons-item {
    --background: #e5e5e5;
    display: block;
    justify-content: space-between;
    border-radius: 3px;
    width: 100%;
}
  
.binary-button {
    width: 100%;
    color: white;
    border-radius: 0;
}
  
