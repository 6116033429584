.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #2c3e50;
    height: 100vh;
    text-align: center;
    color: #fff;
}
  
.error-image {
    width: 60%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.error-content {
    width: 100%;
}
  
.error-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-shadow: 1px 1px 5px #000;
}
  
.error-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    line-height: 1.5;
}
  