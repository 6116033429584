
.ranking-table {
    display: inherit;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 1rem;
}
  
.ranking-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.ranking-cell-header {
    font-size: 1rem;
    color: black;
    width: 100px;
    font-weight: bold;
}

.ranking-cell-data {
    font-size: 1.5rem;
    color: black;
    width: 100px;
}
  
.ranking-cell:not(:last-child) {
    margin-right: 10px;
}
  

.Ranking-user{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max(20vh,250px);
}

.Ranking-user-avatar{
    width: 50%;
    height: 50%;
    object-fit: contain;
}

.Ranking-user p {
    width: max-content;
    font-size: 1rem;
    color: black;
}

.Ranking-user-ranking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
    background-color: beige
}

.Ranking-Scroll{
    overflow-y: scroll;
    padding: 10px;
    box-sizing: border-box;
    scrollbar-width: none;
    max-height: 500px;
}

.Ranking-Scroll::-webkit-scrollbar {
    display: none;
}

.Ranking-Column{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    height: 100%;
    width: 100%;
}

.Ranking-Column-Rank{
    width:15%;
    height: auto;
    text-align: center;
}

.Ranking-Column-Data{
    width: 70%;
    height: auto;
    text-align: center;
}

.Ranking-Column-Points{
    width: 15%;
    height: auto;
    text-align: center;
}

.Ranking-Item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
    height: 100%;
    width: 100%;
}

.Ranking-Item-Rank{
    width: 10%;
    height: auto;
    text-align: center;
    font-size: 1.5rem;
    color: black;
}

.Ranking-Item-Data{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    height: auto;
}

.Ranking-Item-Data p{
    min-width: 150px;
    font-size: 1rem;
    color: black;
}

.Ranking-Item-Data img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.Ranking-Item-Points{
    font-size: 1.5rem;
    color: black;
    width: 10%;
    height: auto;
    text-align: center;
}

.glow {
    animation: glow 1s ease-in-out infinite alternate;
    background-color: #8ec04480;
}