#languageSelector{
    
    position: fixed;
    width:max-content;
    height: max-content;
    background-color: #8ec044;
    border-radius: 0.5rem;
    top: 0;
    right: 0;
    margin: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem; 
    color: black;
    text-align: center;
    border-width: 0 0 0 0;
    --placeholder-opacity: 1;
}

