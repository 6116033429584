.ChapterThumbnail{
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 20px;
    padding: 10px;
    padding-bottom: 0px;
    background-color: white;
}

.ChapterThumbnail:hover{
    box-shadow: 0 0 10px 0 rgb(7, 129, 76);
}

.ChapterThumbnail img {
    height: 110px;
    object-fit: contain;
}

.ChapterThumbnail p {
    font-weight: 600;
    color: black;
    text-align: center;
    white-space: normal;
    padding: 5px;
}





