.choose-option{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.choose-option-item{
    width: 30%;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
    object-fit: contain;
}

.choose-option-item:hover{
    filter: brightness(1.1);
}
