.user-avatar-container{
    width: 100%;
    height: 150px;
    margin: auto;
}

.user-avatar-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.avatar_list-container{
    width: 100%;
    height: 100%;
}

.avatar_list-item{
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
}

.avatar_list-item:hover{
    scale: 1.1;
    cursor: pointer;
    filter: brightness(1.1);
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 1rem;
}



