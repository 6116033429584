ion-spinner {
    width: 100px;
    height: 100px;
    --color: #54dc98;
}

.roundTop{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.roundBottom{
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Logo-Icon {
    width: 5%;
    height: 5%;
}

.logoGreenCo {
    width: 100%;
    max-width: 400px;
    height:auto;
    align-self: center;
}

.imgInvis {
    width: 0rem;
}

#forgotPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 0.95rem;
    color: rgb(175, 175, 175);
    text-decoration: underline;
}

.optionButtons {
    font-weight: bold;
}

.styleLinks {
    text-decoration: none;
}

.genderRadioButtons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    height: 2rem;
    margin-top: 0.5rem;
    background-color: white;
}

#btnRegister {
    margin-top: 0.5rem;
}

/* Recover Password */
#recoverPasswordForm{
    text-align: center;
}

#recoverPasswordForm ion-title{
    font-size: 1.5rem;
    font-weight: bold;
    color: #91949d;
    margin-bottom: 0.5rem;
}

#cardRecoverPass{
    height: auto;
    width: 23rem;
}

#submitChangePass{
    margin-top: 1rem;
}

#backToLogin{
    width: auto;
    margin-top: 0.5rem;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 20%;
    left: 9%;
    --background: none;
}

#backToLogin img{
    width: 1rem;
    height: 1rem;
    transform: scaleX(-1);
    filter: invert(100%);
}

/* TOAST */
ion-toast.toastAuthTab {
    --width: 22rem;
    --white-space: pre-wrap
    font-size: 10px;
    font-weight: bold;
}


ion-toast.toastAuthTab::part(button) {
    font-weight: bold;
    font-size: 15px;
    border-left: 1px solid #d2d2d2;
}
div {
    text-align: center;
}


/* Absolute position */

.left {
    position: absolute;
    left: 0;
}
