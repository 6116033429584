.StoryContainer {
    padding: 1rem;
    border-radius: 5px;
    width: 100%;
    height: auto;
    overflow: scroll;
}

.Hangman-Container{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: none;
    padding: 1rem;
}

.Hangman-Container-Image{
    width: min(50%, 300px);
    height: min(60%, 400px);
}

.Hangman-Container-Text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #0000001a;
    border-radius: 3px;
    background-color: #7ab8f5;
    box-shadow: inset 0 0 10px #0000001a;
    color: white;
    font-weight: bold;
}

.Hangman-Container-Text p{
    padding: 0.5rem;
    text-align: center;
    margin: 0.25rem;
}

.Hangman-Container-Text p b{
    color: #000;
}

.Hangman-Container-Text-Description{
    font-weight: 200;
}

.Hangman-HiddenWord{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 1rem;
    width: min(max-content, 100%);
    padding: 10px;
    border-radius: 3px;
    background-color: #e5e5e5;
    color: black;
}

.Hangman-UserInput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Hangman-UserInput input{
    width: 40px;
    height: 40px;
    border-radius: 0;
    text-align: center;
    margin: 0 0.5rem;
    border: none;
    padding: 0.5rem;
    background-color: #ffffff;
    color: black;
}

.Hangman-UserInput input:focus{
    outline: none;
}

.IonButton{
    background-color: #333;
    color: #ffffff;
    padding: 0.5rem 2rem;
    border-radius: 0;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

IonButton:hover{
    background-color: #666;
}

.IonText {
    color: #333;
}

.IonText b {
    color: #000;
}

.top-right{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
}
.hangmanImage {
    max-width: 200px;
}
